/** Import modules */

import { gql } from "@apollo/client";

const 

/** Rims collection */

RimsCatalog = gql`query productQuery ( 
    
    $first                  : Int, 
    
    $last                   : Int, 
    
    $after                  : String, 
    
    $before                 : String, 
    
    $sortOrder              : String,
    
    $manufacturer           : [String!],

    $model                  : [String!],

    $pitchCircleDiameter    : [String!],

    $diameter               : [String!],

    $upperPriceRange        : Int,

    $lowerPriceRange        : Int,
    
    $offset                 : [Int!] 
    
    $availability           : [AvailabilityTypeEnum!]

    # $color                  : [String!]
    
    ), {
    
        multiFieldProductSearch ( 
        
            first                   : $first, 
            
            last                    : $last, 
            
            productType             : "rim", 
        
            after                   : $after, 
        
            before                  : $before, 
            
            sortOrder               : $sortOrder,

            manufacturer            : $manufacturer,

            model                   : $model,

            diameter                : $diameter,

            pitchCircleDiameter     : $pitchCircleDiameter,

            upperPriceRange         : $upperPriceRange,

            lowerPriceRange         : $lowerPriceRange,

            offset                  : $offset,

            availability            : $availability

            # color                   : $color
            
        ) { 
            
        edges { 
            
            node {

                awsImageUrl

                currentImage
        
                boltsIncluded
                
                centreBore
                
                createdAt
                
                dedicatedAxle
                
                description
                
                diameter
                
                hashId
                
                height
                
                id
                
                image
                
                length
                
                manufacturer {
                
                name
                
                }
                
                manufacturerId
                
                model
                
                offset
                
                pitchCircleDiameter
                
                priceList
                
                productVendors {

                    price
          
                    qty 
                    
                    qtyDays
                    
                    qtyHour
                    
                    qtyWeeks

                }

                productType
                
                radius
                
                studs
                
                uid
                
                updatedAt
                
                url
                
                usage
                
                used
                
                visible
                
                width
                
                year
                
                specifications

                color

            } 
    
        }
            
        pageInfo { 
            
            hasNextPage 
            
            hasPreviousPage 
            
            startCursor 
            
            endCursor 
        
        }

    }

}`,

/** Single product */

SingleProduct = gql`query SingleProductQuery ( $id: [ID!]! ) { product ( id: $id ) { 

    edges { node {

        awsImageUrl

        currentImage
        
        boltsIncluded
        
        centreBore
        
        createdAt
        
        dedicatedAxle
        
        description
        
        diameter
        
        hashId
        
        height
        
        id
        
        image
        
        length
        
        manufacturer {
        
        name
        
        }
        
        manufacturerId
        
        model
        
        offset
        
        pitchCircleDiameter
        
        priceList

        productVendors {

            price
          
            qty 
            
            qtyDays
            
            qtyHour
            
            qtyWeeks

        }
        
        productType
        
        radius
        
        studs
        
        uid
        
        updatedAt
        
        url
        
        usage
        
        used
        
        visible
        
        width
        
        year
        
        specifications

        color

    } }
          
} }`,

/** Tyre filter attributes */

FilterAttributes = gql`query { searchAttributes }`;

/** Export queries */

export { 
    
    RimsCatalog, 
    
    FilterAttributes, 
    
    SingleProduct 

};