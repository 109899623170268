/** @import modules */

import { Link }             from "react-router-dom";

import { useTranslation }   from "react-i18next";

/** @import misc */

import { RimPermalinks }    from "../../../../../Misc/Permalinks";

import { productClick }     from "../../../../../Misc/DataLayer";

/** @import assets */

import NoProduct from "../../../../../Assets/no-rims.jpg";

/** @import components */

import Properties from "./Properties";

import Availability from "../../../Tyres/Catalog/Item/Availability";

import AddCart from "./AddCart";

/**
 * 
 * @param { product } param0 
 * 
 * @returns Catalog grid item component
 * 
 */

const GridItem = ( { product, notice } ) => { const permalink = RimPermalinks ( product ),

    { t } = useTranslation ();

    /** Return component */

    return ( <div className="item">

        { ( notice.view === product.id ) && <div className="cart-added-notice">
            
            { t ( "item_added_to_cart" ) }
            
        </div> }

        <Link to={ permalink } onClick={ () => { productClick ( product ) } }>

            <div className="thumbnail rims">

                { ( ! product.currentImage ) ? 
                
                    <img src={ NoProduct } alt={ product.model } /> : 
                    
                    <img src={ product.currentImage } alt={ product.model } onError={ ( { currentTarget } ) => {

                        currentTarget.src = NoProduct;
            
                    } } /> }

            </div>

        </Link>

        <Link to={ permalink } onClick={ () => { productClick ( product ) } }>

            <div className="name">
                
                <div className="model">

                    <span>{ product.manufacturer.name }</span>

                    { product.model }

                </div>

                { product?.color &&
                
                    <div className="productColor">

                        { product?.color }

                    </div>
                
                }

                <div className="size">

                    { `${ product?.width || "-" }J ${ parseInt ( product?.diameter || "-" ) }' ${ product?.pitchCircleDiameter || "-" } ET${ product?.offset || "-" } | CB${ product?.centreBore || "-" }` }

                </div>
                
            </div>

        </Link>

            <div className="features-properties">

                <Properties product={ product } />

            </div>

            <Availability counter={ product.productVendors[0] } product={ product } />

            <AddCart product={ product } link={ permalink } notice={ notice } />

            <div className="price">
                
                &euro; { parseFloat ( product.priceList.retail_price ).toFixed ( 2 ) }
                
            </div>

    </div> );

}

/** Export component */
 
export default GridItem;