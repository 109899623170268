/** @import assets */

import { useTranslation } from "react-i18next";

import NoTires from "../../../../Assets/no-tyres.jpg";

import NoRims from "../../../../Assets/no-rims.jpg";

import NoBatteries from "../../../../Assets/no-batteries.jpg";

import { FaRoadSpikes } from "react-icons/fa6";

/**
 * 
 * @param { data } param0 
 * 
 * @returns Single product image component
 * 
 */

const Thumbnail = ( { data } ) => { const 

    { image, model, type } = data,

    noImage = () => {

        if ( type === "tire" ) return NoTires;

        if ( type === "rim" ) return NoRims;

        if ( type === "battery" ) return NoBatteries;

    };

    const { t } = useTranslation ();

    console.log(data);

    /** Return component */

    return ( <div className="image">

        { ( ! image ) ? 
                
            <img src={ noImage } alt={ model } /> : 
            
            <img src={ image } alt={ model } onError={ ( { currentTarget } ) => {

                if ( type === "tire" ) return currentTarget.src = NoTires;

                if ( type === "rim" ) return currentTarget.src = NoRims;

                if ( type === "battery" ) return currentTarget.src = NoBatteries;
    
            } } /> }


            {

                ( data?.studs === "yes" || data?.studs === "possible" ) &&

                <div className="productImageStudsIndicator">

                    <span className="productImageStudsIndicatorIcon">

                        <FaRoadSpikes size={ 18 } />

                    </span>
                    
                    { data?.studs === "yes" ? t ( "studs" ) : t ( `studs_${ data?.studs }` ) }
                    
                </div>

            }

    </div> );

}

/** Export component */
 
export default Thumbnail; 