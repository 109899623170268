/** Import assets */

import { Fragment } from "react";

import { Helmet }   from "react-helmet-async";

import "../../../../Styles/Products.css";

/**
 * 
 * @param { title, children } param0 
 * 
 * @returns single product layout component
 * 
 */

const SingleProductLayout = ( { data, children } ) => {

    /** Return component */

    return ( <div className={ `content single-product single-${ data.productType }` }>

        <Helmet>

            <title>{ `Tireshop.lv | ${ data.manufacturer.name.toUpperCase () } ${ data.model }` }</title>

            <link rel="canonical" href={ `/shop/tyre/${ data.id }` } />

        </Helmet>

        <div className="title">
            
            <h2>

                { `${ data.manufacturer.name.toUpperCase () } - ${ data.model } - ` }

                { ( data.productType === "tire" || data.productType === "rim" ) &&

                    <span>

                        { data.productType === "tire" && <Fragment>

                            { `${ data.width } | ${ data.height } | R${ parseInt ( data.diameter ) }${ data?.isCTire ? "C" : "" } ${ data?.loadIndex ?  `| ${data?.loadIndex}` : "" }${ data?.speedRating ? data?.speedRating?.toUpperCase () : "" }` }

                        </Fragment> }

                        { data.productType === "rim" && <Fragment>

                            {/* { `${ data?.width || "-" }J | R${ parseInt ( data?.diameter ) || "-" } | ${ data?.pitchCircleDiameter || "-" } | ET${ data?.offset || "-" } | CB${ data?.centreBore || "-" } | ${ data?.color }` } */}

                            {

                                [

                                    data?.width ? `${ data?.width }J` : null,

                                    data?.diameter ? `R${ parseInt ( data?.diameter ) }` : null,

                                    data?.pitchCircleDiameter ? `${ data?.pitchCircleDiameter }` : null,

                                    data?.offset ? `ET${ data?.offset }` : null,

                                    data?.centreBore ? `CB${ data?.centreBore }` : null,

                                    data?.color ? `${ data?.color }` : null

                                ].filter ( Boolean ).join ( " | " )

                            }

                        </Fragment> }

                    </span>

                }

            </h2>

            <span className="product_id">

                ID: { data.id }

            </span>
            
        </div>
    
        <div className="layout">

            <section>{ children }</section>

        </div>
    
    </div> );

}

/** Export component */
 
export default SingleProductLayout;