/** @import modules */

import { gql } from "@apollo/client";

const 

/** Cart products */

CartProducts = gql`query SingleOrdersProduct ( $id: [ID!]! ) {
  
    product ( id: $id ) { 
        
        edges { 
            
            node {
        
                id

                awsImageUrl

                currentImage

                model

                width

                height

                diameter

                radius

                loadIndex

                speedRating

                season

                description

                productType

                voltage

                capacity

                current

                pitchCircleDiameter

                offset

                centreBore

                wetBreakingEfficiency

                productVendors {

                    price
          
                    qty 
                    
                    qtyDays
                    
                    qtyHour
                    
                    qtyWeeks

                }

                manufacturer {

                    name

                }

                priceList

                isCTire

                color

                studs

                noiseIndex

                fuelEfficiency

                year
            
            }
            
        } 
    
    }

}`,

CartSummary = gql`query OrderSummary ( 
    
    $productParams: [JSON!]!

    $clientId: Int

    $deliveryPlanId: Int

    $deliveryPrice: Float!

    $servicePrice: Float!

    $vat: Boolean!

    $city: String!

    $countryCode: String!
    
    ) {

    orderPriceCalculator ( 
        
        productParams: $productParams

        clientId: $clientId

        deliveryPlanId: $deliveryPlanId

        deliveryPrice: $deliveryPrice

        city: $city

        countryCode: $countryCode

        servicePrice: $servicePrice

        vat: $vat
        
    )

}`,

OrderOptions = gql`query { orderOptions }`,

/** @name DELIVERY_PLANS */

DELIVERY_PLANS = gql`query { deliveryPlans { id, value } }`,

RETRY_PAYMENT = gql`query RetryPayment ( $orderHashId: String! ) {

    orderRetryPayment ( orderHashId: $orderHashId ) {

        paymentLink

    }

}`;

/** @export */

export { 
    
    CartProducts,

    CartSummary,

    OrderOptions,

    DELIVERY_PLANS,

    RETRY_PAYMENT

};