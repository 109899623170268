import styles from "./Popover.module.css";

/**
 *
 * @description
 *
 * Popover component that displays a tooltip on hover.
 *
 * @param {string} text - The text to display in the tooltip.
 *
 * @example
 *
 * <Popover text="This is a tooltip">
 *
 *     <button>Hover me</button>
 *
 * </Popover>
 *
 */

export default function Popover({
  text,
  size = "large",
  position = "top",
  children,
}) {
  return (
    <span className={styles.container}>
      {children}

      <p className={styles.content} data-size={size} data-position={position}>
        {text}
      </p>
    </span>
  );
}
