/** Import modules */

import { Link }             from "react-router-dom";

import { useTranslation }   from "react-i18next";

import ReactGA              from 'react-ga';

/** Import components */

import Features             from "./Features";

import ProductProperties    from "./Props";

import AddCart              from "./AddCart";

import Availability         from "./Availability";

/** Import misc */

import { TyrePermalinks }   from "../../../../../Misc/Permalinks";

import { productClick } from "../../../../../Misc/DataLayer";

/** @import assets */

import NoProduct from "../../../../../Assets/no-tyres.jpg";

import { FaRoadSpikes } from "react-icons/fa6";

/**
 * 
 * @param { product } param0 
 * 
 * @returns Catalog grid item component
 * 
 */

const GridItem = ( { product, notice } ) => { const { t } = useTranslation (),
    
    permalink = TyrePermalinks ( product );

    /** Return component */

    return ( <div className={ `item G${ product.id }` }>

        { ( notice.view === product.id ) && <div className="cart-added-notice">
            
            { t ( "item_added_to_cart" ) }
            
        </div> }

        <Link to={ permalink } onClick={ () => { productClick ( product ) } }>

            <div className="thumbnail tyres">

                { ( ! product.currentImage ) ? 
                
                    <img src={ NoProduct } alt={ product.model } /> : 
                    
                    <img src={ product.currentImage } alt={ product.model } onError={ ( { currentTarget } ) => {

                        currentTarget.src = NoProduct;
            
                    } } /> }

                {
                
                    ( product?.studs === "yes" || product?.studs === "possible" ) &&
    
                    <div className="productImageStudsIndicator">
    
                        <span className="productImageStudsIndicatorIcon">
    
                            <FaRoadSpikes size={ 18 } />
    
                        </span>
                        
                        { product?.studs === "yes" ? t ( "studs" ) : t ( `studs_${ product?.studs }` ) }
                        
                    </div>
    
                }

            </div>

        </Link>

        <Link to={ permalink } onClick={ () => { productClick ( product ) } }>

            <div className="name">
                
                <div className="model">

                    <span>{ product.manufacturer.name }</span>

                    { product.model }

                </div>

                <div className="size">

                    { `${ product.width } | ${ product.height } | R${ parseInt ( product.diameter ) }${ product?.isCTire ? "C" : "" } ${ product?.loadIndex ? `| ${product?.loadIndex}` : "" }${ product?.speedRating ? product?.speedRating.toUpperCase () : ""}` }

                </div>
                
            </div>

        </Link>

        <div className="features-properties">

            <ProductProperties product={ product } />

            <Features product={ product } />

        </div>

        <Availability counter={ product.productVendors[0] } product={ product } />

        <AddCart product={ product } link={ permalink } notice={ notice } />

        <div className="price">
            
            &euro; { parseFloat ( product.priceList.retail_price ).toFixed ( 2 ) }
            
        </div>

    </div> );

}

/** Export component */
 
export default GridItem;